import * as React from 'react';

import { useStyles } from './styles';
import {Box, Button, TextField} from "@mui/material";
import {AAV_LINK} from '../../constants'
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses, TooltipProps} from "@mui/material/Tooltip";
import {useState} from "react";
import OrderCancelForm from "../OrderCancelForm";
import OrderVoidLabel from "../OrderVoidLabel";
import {useSelector} from "react-redux";
import {IStore} from "../../store/types";

const OrderStatus = {
    1: {status: 'Created', color:'#00ccff54'},
    2: {status: 'Production', color:'#ffa500ab'},
    3: {status: 'Shipped', color:'#4bd30899'},
    4: {status: 'Delivered', color:'rgba(192,192,192,0.92)'},
    5: {status: 'Canceled', color:'#80808073'},
    6: {status: 'Error', color:'#ff000073'},
    7: {status: 'Partially Shipped', color:'#8ffa98'},
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        maxWidth: 400,
        border: '1px solid #dadde9',
    },
}));


export default function GeneralForm(props: any) {

    const { data } = props.data;
    const classes = useStyles();
    const [isLabelPopUp, setLabelPopUp] = useState(false);
    const [labelInfo, setLabelInfo] = useState();
    const role = useSelector<IStore, any>((state) => {
        return state.auth.data.role});
    function formatString(str) {
        // Remove leading and trailing spaces
        str = str.trim();

        // Replace spaces between words with "-"
        str = str.replace(/\s+/g, "-");

        return str;
    }
    const popUpCancelHandler = (cancel?: boolean) =>{
        setLabelPopUp(prevState => !prevState)
    }

    const voidLabelPopUpHandler = (info) => {
        setLabelInfo(info);
        setLabelPopUp(prevState => !prevState)
    }

    return (
        <>
            <OrderVoidLabel isPopUp={isLabelPopUp} popUpHandler={popUpCancelHandler} labelInfo={labelInfo}/>
            <div className={classes.authorWrap}>
                <div className={classes.authorHeader}><span>General Info</span></div>
                <div className={classes.inputWrap}>
                    <TextField label="Shipping Method Code" variant="outlined" InputProps={{
                        readOnly: true,
                    }} value= {data?.shippingMethodCode || ''} sx={{ m: 1, width: '30ch'}}/>
                    <TextField label="External Id" variant="outlined" InputProps={{
                        readOnly: true,
                    }} value= {data.externalOrderId || ''}  sx={{ m: 1, width: '30ch'}}/>
                    <TextField label="Created at" variant="outlined" InputProps={{
                        readOnly: true,
                    }} value= {data.createdAt || ''} sx={{ m: 1, width: '30ch'}}/>
                </div>
                <div className={classes.inputWrap}>
                    <TextField label="Order Status" variant="outlined" InputProps={{
                        readOnly: true,
                    }} value= { OrderStatus[data.orderStatusId].status || ''} sx={{ m: 1, width: '30ch'}}/>
                    <TextField label="In ShipStation" variant="outlined" InputProps={{
                        readOnly: true,
                    }} value= {data.shipstationCreated? 'Yes': "No" || ''} sx={{ m: 1, width: '30ch'}}/>
                    <TextField label="Updated At" variant="outlined" InputProps={{
                        readOnly: true,
                    }} value= {data.updatedAt || ''}  sx={{ m: 1, width: '30ch'}}/>
                </div>
            </div>
            {data?.shipments && data?.shipments.length > 0 && <div className={classes.authorWrap}>
                <div className={classes.authorHeader}><span>Shipments</span></div>

                    <div style={{ overflow: "scroll" }}>
                        <Table sx={{  }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align={'center'}><b>ShipmentID</b></TableCell>
                                    <TableCell align={'center'}><b>TrackingNumber</b></TableCell>
                                    <TableCell align={'center'}><b>CarrierCode</b></TableCell>
                                    <TableCell align={'center'}><b>ServiceCode</b></TableCell>
                                    <TableCell align={'center'}><b>ShipDate</b></TableCell>
                                    <TableCell align={'center'}><b>ShipmentCost</b></TableCell>
                                    {(role === 'moderator' || role === 'admin') &&
                                    <TableCell
                                        align={'center'}
                                        style={{ minWidth: 170 }}
                                    >
                                    </TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.shipments.map((row) => (
                                    <TableRow
                                        key={row.shipmentId}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align={'center'}>
                                            {row.shipmentId}
                                        </TableCell>
                                        <TableCell align={'center'}>{row.trackingNumber}</TableCell>
                                        <TableCell align={'center'}>{row.carrierCode}</TableCell>
                                        <TableCell align={'center'}>
                                            {row?.serviceCode}
                                        </TableCell>
                                        <TableCell align={'center'}>{row.shipDate}</TableCell>
                                        <TableCell align={'center'}>{row.shipmentCost}</TableCell>
                                        {(role === 'moderator' || role === 'admin') &&
                                            <TableCell
                                                align={'center'}
                                                style={{ minWidth: 170 }}
                                            >
                                                {row.voided === 0 ? <Button className={classes.editButton}
                                                                            disabled={data?.orderStatusId === 7 || data?.orderStatusId === 3 ? false : true}
                                                                            onClick={() => {
                                                                                voidLabelPopUpHandler({shipmentId: row.shipmentId})
                                                                            }}
                                                    >Void Label</Button>
                                                    : <b>VOIDED</b>}

                                            </TableCell>
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
            </div>}
            {(role === 'moderator' || role === 'admin') &&
            <div className={classes.authorWrap}>
                <div className={classes.authorHeader}><span>Recipients</span></div>
                {data.recipient &&
                    <div style={{ overflow: "scroll" }}>
                    <Table sx={{  }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align={'center'}><b>Name</b></TableCell>
                                <TableCell align={'center'}><b>Country</b></TableCell>
                                <TableCell align={'center'}><b>City</b></TableCell>
                                <TableCell align={'center'}><b>Street</b></TableCell>
                                <TableCell align={'center'}><b>Postal Code</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                <TableRow
                                    key={data.recipient?.giftcardId}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align={'center'}>
                                        {data.recipient.name}
                                    </TableCell>
                                    <TableCell align={'center'}>{data.recipient.country}</TableCell>
                                    <TableCell align={'center'}>{data.recipient.city}</TableCell>
                                    <TableCell align={'center'}>
                                        {data.recipient?.street1}<br/>
                                        {data.recipient?.street2}
                                    </TableCell>
                                    <TableCell align={'center'}>{data.recipient.postalCode}</TableCell>
                                </TableRow>
                        </TableBody>
                    </Table>
                </div>}
            </div>}
            <div className={classes.authorWrap}>
                <div className={classes.authorHeader}><span>Order Items</span></div>
                {data.items && data?.items.length > 0 &&
                    <div style={{ overflow: "scroll" }}>
                        <Table sx={{  }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align={'center'}><b>SKU</b></TableCell>
                                    <TableCell align={'center'}><b>External ItemId</b></TableCell>
                                    <TableCell align={'center'}><b>ItemId</b></TableCell>
                                    <TableCell align={'center'}><b>Quantity</b></TableCell>
                                    <TableCell align={'center'}><b>Mockup UrL</b></TableCell>
                                    <TableCell align={'center'}><b>Print Files</b></TableCell>
                                    {/*<TableCell align={'center'}><b>Original URL</b></TableCell>*/}
                                    {(role === 'moderator' || role === 'admin') &&
                                    <TableCell align={'center'}><b>Price per Item</b></TableCell>}
                                    {(role === 'moderator' || role === 'admin') &&
                                    <TableCell align={'center'}><b>Total Price</b></TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.items.map((row) => (
                                    <TableRow
                                        key={row.giftcardId}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align={'center'}>
                                            {row.sku}
                                        </TableCell>
                                        <TableCell align={'center'}>{row.externalItemId}</TableCell>
                                        <TableCell align={'center'}>{row.orderItemId}</TableCell>
                                        <TableCell align={'center'}>{row.quantity}</TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 170, paddingTop: '5px',paddingBottom: '5px'  }}
                                        >
                                            <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            <img style={{maxHeight:'400px',maxWidth: '380px', width: 'auto'}} src={row.printFiles[0].mockupUrlInternal} />
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <img style={{maxHeight:'65px', width: 'auto'}} src={row.printFiles[0].mockupUrlInternal} />
                                                </HtmlTooltip>
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            <img style={{maxHeight:'400px',maxWidth: '380px', width: 'auto'}} src={row.printFiles[1].mockupUrlInternal} />
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <img style={{maxHeight:'65px', width: 'auto'}} src={row.printFiles[1].mockupUrlInternal} />
                                                </HtmlTooltip>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 170, paddingTop: '5px',paddingBottom: '5px'  }}
                                        >
                                            <div style={{display: 'flex',  width: '100%', justifyContent: 'center'}}>
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            <img style={{maxHeight:'400px',maxWidth: '380px', width: 'auto'}} src={row.printFiles[0]?.printFileReadyUrl} />
                                                        </React.Fragment>
                                                    }
                                                >
                                                    {row.printFiles[0]?.printFileReadyUrl ? <img style={{maxHeight:'65px', width: 'auto'}} src={row.printFiles[0]?.printFileReadyUrl} /> : <div style={{padding:'20px 10px'}}>{row.printFiles[0].type}</div>}
                                                </HtmlTooltip>
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            <img style={{maxHeight:'400px',maxWidth: '380px', width: 'auto'}} src={row.printFiles[1].printFileReadyUrl} />
                                                        </React.Fragment>
                                                    }
                                                >
                                                    {row.printFiles[1]?.printFileReadyUrl ? <img style={{maxHeight:'65px', width: 'auto'}} src={row.printFiles[1]?.printFileReadyUrl} /> : <div style={{padding:'20px 10px'}}>{row.printFiles[1].type}</div>}
                                                </HtmlTooltip>
                                            </div>
                                        </TableCell>
                                        {/*<TableCell align={'center'}>*/}
                                        {/*    <a href={row.printFiles[0].url} target={'_blank'}>Front URl </a>*/}
                                        {/*    <br/>*/}
                                        {/*    {row.printFiles[1]?.url &&*/}
                                        {/*        <a href={row.printFiles[1].url} target={'_blank'}>Back URl </a>*/}
                                        {/*    }*/}
                                        {/*</TableCell>*/}
                                        {(role === 'moderator' || role === 'admin') &&
                                        <TableCell align={'center'}>{row.itemPrice}</TableCell>}
                                        {(role === 'moderator' || role === 'admin') &&
                                        <TableCell align={'center'}>{row.itemTotalPrice}</TableCell>}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>}
                {(role === 'moderator' || role === 'admin') &&
                <div className={classes.totalPrising}>
                    <div>Total order price: <b>{data.totalOrderPrice}</b></div>
                    <div>Total price with shipment: <b>{data.totalOrderPriceWithShipment}</b></div>
                </div>}
            </div>
        </>
    );
}