import {getAnalytics3d} from "../../thunk";

const getID = (id) => {
    return `a${id}a`
}

export default {
    [getAnalytics3d.pending as any]: (state) => {
        state.status = 'loading';
        state.error = null;
    },
    [getAnalytics3d.fulfilled as any]: (state, action) => {
        state.status = 'resolved';
        state.data = action.payload;
        state.queryParams = {...action.meta.arg ,page: +action.payload.page, perPage: +action.payload.perPage, total: +action.payload.totalCount}
    },
    [getAnalytics3d.rejected as any]: (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
    },
}
