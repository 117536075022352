import * as React from 'react';
import { useNavigate } from 'react-router';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import { useStyles } from './styles';



const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#0000000a',
    },
}));
const StoryStatus = {
    1: {status: 'Created', color:'#00ccff54'},
    2: {status: 'Pending review', color:'#ffa500ab'},
    3: {status: 'Approved', color:'#4bd30899'},
    4: {status: 'Rejected', color:'#80808073'},
    5: {status: 'Error on creation', color:'#ff000073'},
    6: {status: 'Error on review', color:'#ff000073'},
    7: {status: 'E!!!!!!!', color:'#ff000073'},
}

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        maxWidth: 400,
        border: '1px solid #dadde9',
    },
}));



export default function StoriesTable(props: any) {
    const { stories, paging, pagingUpdate } = props;

    const classes = useStyles();
    const navigator = useNavigate();

    const handleChangePage = (event: unknown, newPage: number) => {
        pagingUpdate({...paging, page: newPage+1, perPage: paging.perPage})
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        pagingUpdate({...paging, page: 1, perPage: +event.target.value})
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: '65vh', position: 'relative'}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 70 }}
                            >
                              Story ID
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 170 }}
                            >
                                Image
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                Date
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 170 }}
                            >
                                Author Name
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 170 }}
                            >
                                Story Status
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                Is Published
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 170 }}
                            >
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 170 }}
                            >
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stories
                            .map((story) => {
                                return (
                                    <StyledTableRow tabIndex={-1} key={story.storyId}>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 70 }}
                                        >
                                            {story.storyId}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 170, paddingTop: '5px',paddingBottom: '5px'  }}
                                        >
                                            <div>
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            <img style={{maxHeight:'400px',maxWidth: '380px', width: 'auto'}} src={story.imageLink} />
                                                        </React.Fragment>
                                                    }
                                                >
                                                <img style={{maxHeight:'65px', width: 'auto'}} src={story.imageLink} />
                                                </HtmlTooltip>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {formatDate(story.date)}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 170 }}
                                        >
                                            {story.firstName}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 170 }}
                                        >
                                            <div className={classes.storyStatus} style={{backgroundColor: `${StoryStatus[story.statusId].color || 'green'}`}}>
                                                {StoryStatus[story.statusId].status}
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            <span style={{color: `${story.isPublished ? 'green': 'grey'}`,fontWeight: 600}}>
                                                  {story.isPublished ? 'Published': 'Not Published' }
                                            </span>
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 170 }}
                                        >
                                            <a href={`https://dash.bunny.net/stream/${story.videoLink.substring(0, story.videoLink.indexOf("/"))}/library/overview?videoId=${story.videoLink.substring(story.videoLink.indexOf("/")+1)}#noscroll`}
                                               target="_blank"
                                               className={classes.linkButton}
                                            >Video Link</a>
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 170 }}
                                        >
                                            <div className={classes.editButton}
                                                 onClick={() => {
                                                     // dispatch(ordersActions.clearOneOrderInfo());
                                                     navigator(`/stories/${story.storyId}/details`);
                                                 }}
                                            >Edit</div>
                                        </TableCell>
                                    </StyledTableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 15, 25]}
                component="div"
                count={paging.total}
                rowsPerPage={paging.perPage}
                page={paging.page-1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}