import React, {useState, useEffect, Fragment} from 'react';
import { useSelector, useDispatch } from 'react-redux';
/** Components */
import Skeleton from '@mui/material/Skeleton';
import PageContainer from '../../components/PageContainer';
import StoriesTable from '../../components/StoriesTable';
import StoriesFilters from '../../components/StoriesFilters';
/** Selectors */
import {IStory, IStore, IOneStory} from "../../store/types";
import {Stories} from '../../store/slices'
import {useSearchParams} from "react-router-dom";
import {useNavigate} from "react-router";

const DEFAULT_PAGING_STATE = {
  perPage: 25,
  page: 1,
  total: 0
};


const StoriesPage = () => {
  const {
    stories,
    status,
    queryParams
  } = useSelector<IStore, { stories: Record<string, IOneStory>[], status: string , queryParams: any}>(state => ({
    stories: Object.values(state.stories.data),
    status: state.stories.status,
    queryParams: state.stories.queryParams
  }))

  const dispatch = useDispatch();
  const navigator = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const authorId = searchParams.get("authorId");

  const loadStories = (params) => {
    dispatch<any>(Stories.getStories(params));
  };
  useEffect(() => {
    loadStories({
      ...queryParams,
      page: queryParams?.page || DEFAULT_PAGING_STATE.page,
      perPage: queryParams?.perPage || DEFAULT_PAGING_STATE.perPage,
      authorId
    });
  }, [authorId])
  return (
    <PageContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between'}}>
        <h2 style={{marginTop: 0, color:'#047d95', fontWeight: 600}}>Stories
          {authorId && ` by authorID: ${authorId}`}
        </h2>
        <div
            onClick={()=> navigator('/stories/create')}
            style={{
              padding:'8px 30px',
              width: 'fit-content',
              backgroundColor: '#01ac9e',
              color: 'white',
              cursor:'pointer',
              fontWeight: 600,
              borderRadius: 5,
              height: "40px"
              }}
        >
          Create
        </div>
      </div>
          <StoriesFilters queryParams={{...DEFAULT_PAGING_STATE, ...queryParams}} loadStories={loadStories} loading={status === 'loading'}/>
      {(stories?.length > 0 && status !== 'loading') &&
          <StoriesTable stories={stories} paging={{...DEFAULT_PAGING_STATE, ...queryParams}} pagingUpdate={loadStories}/>
      }
      {stories?.length < 1 && status !== 'loading' && <div>Don't found result</div>}
      {status === 'loading' &&
        <Skeleton animation="wave" width={'100%'} height={600} variant="rounded" />}
    </PageContainer>

  );
};

export default StoriesPage;
