import * as React from 'react';
import {useStyles} from "./styles";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {Button, CircularProgress, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {Stories} from "../../store/slices";

const StoryStatus = [
    // {status: 'All', value: null },
    {status: 'Created', color:'#00ccff54', value:1},
    {status: 'Pending review', color:'#ffa500ab', value:2},
    {status: 'Approved', color:'#4bd30899', value:3},
    {status: 'Rejected', color:'#80808073', value:4},
    {status: 'Error on creation', color:'#ff000073', value:5},
    {status: 'Error on review', color:'#ff000073', value:6},
]
const StoryPublished = [
    // {status: 'All', value: null },
    {label: 'Yes', value: '1'},
    {label: 'No', value: '0'},
]
const ProductVariants = [
    {title: '16\" Shaped Pillow', value: 1},
    {title: '24\" Shaped Pillow', value: 2},
    {title: '32\" Shaped Pillow', value: 3},
    {title: 'Keychain Pillow', value: 4},
    {title: '16\"x16\" Square Pillow', value: 5},
    {title: '18\"x18\" Square Pillow', value: 6},
    {title: 'Mini 10\"', value: 7},
    {title: '22\" Shaped Pillow', value: 8},
]

const StoryOccasion = [
    {title: 'Birthday Gift', value: 1},
    {title: 'Christmas Gift', value: 2},
    {title: 'Father’s Day Gift', value: 3},
    {title: 'Graduation Gift', value: 4},
    {title: 'Mother’s Day Gift', value: 5},
    {title: 'Wedding Gift', value: 6},
    {title: 'Back to school', value: 7},
    {title: 'Passing of pet', value: 8},
    {title: 'Just because', value: 9},
    {title: 'Valentines day', value: 10},
    {title: 'Anniversary', value: 11},
    {title: 'Pride Month', value: 13},
]

const StoryRecipient = [
    {title: 'Prefer not to answer', value: 1},
    {title: 'Son', value: 2},
    {title: 'Daughter', value: 3},
    {title: 'Parent', value: 4},
    {title: 'Friend', value: 5},
    {title: 'Co-Worker', value: 6},
    {title: 'Grandparent', value: 7},
    {title: 'Myself', value: 8},
    {title: 'Spouse', value: 9},
    {title: 'Boyfriend/Girlfriend', value: 10},
    {title: 'Child', value: 11},
]


export default function StoriesFilters(props: any) {
    const { queryParams, loadStories, loading } = props;
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        loadStories({
            ...queryParams,
            page: 1,
            [name]: value,
        });
    }
    return (
        <div className={classes.filtersWrap}>
            <FormControl sx={{ m: 1,  width: '15ch', marginLeft: 0 }}  size="small">
                <InputLabel id="demo-simple-select-helper-label">Published</InputLabel>
                <Select
                    id="demo-simple-select-helper"
                    value={queryParams?.isPublished ? queryParams?.isPublished : []}
                    label="Published"
                    variant="outlined"
                    name="isPublished"
                    size="small"
                    disabled={loading}
                    onChange={(event) =>handleFilterChange(event)}
                >
                    {StoryPublished.map((story)=>
                        <MenuItem key={`${story.value}${story.label}`} value={story.value}>{story.label}</MenuItem>
                    )}
                </Select>
            </FormControl>
            <FormControl sx={{ m: 1,  width: '15ch' }}  size="small">
                <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
                <Select
                    id="demo-simple-select-helper"
                    value={queryParams?.statusId ? queryParams?.statusId : []}
                    label="Status"
                    variant="outlined"
                    name="statusId"
                    disabled={loading}
                    onChange={(event) =>handleFilterChange(event)}
                >
                    {StoryStatus.map((story)=>
                        <MenuItem key={`${story.value}${story.status}`} value={story.value}>{story.status}</MenuItem>
                    )}
                </Select>
            </FormControl>
            <FormControl sx={{ m: 1,  width: '15ch' }}  size="small">
                <InputLabel id="demo-simple-select-helper-label">Occasion</InputLabel>
                <Select
                    id="demo-simple-select-helper"
                    value={queryParams?.occasionId ? queryParams?.occasionId : []}
                    label="Occasion"
                    variant="outlined"
                    name="occasionId"
                    disabled={loading}
                    onChange={(event) =>handleFilterChange(event)}
                >
                    {StoryOccasion.map((occasion)=>
                        <MenuItem key={`${occasion.value}${occasion.title}`} value={occasion.value}>{occasion.title}</MenuItem>
                    )}
                </Select>
            </FormControl>
            <FormControl sx={{ m: 1,  width: '15ch' }}  size="small">
                <InputLabel id="demo-simple-select-helper-label">Recipient</InputLabel>
                <Select
                    id="demo-simple-select-helper"
                    value={queryParams?.recipientId ? queryParams?.recipientId : []}
                    label="Recipient"
                    variant="outlined"
                    name="recipientId"
                    disabled={loading}
                    onChange={(event) =>handleFilterChange(event)}
                >
                    {StoryRecipient.map((recipient)=>
                        <MenuItem key={`${recipient.value}${recipient.title}`} value={recipient.value}>{recipient.title}</MenuItem>
                    )}
                </Select>
            </FormControl>
            <FormControl sx={{ m: 1,  width: '15ch' }}  size="small">
                <InputLabel id="demo-simple-select-helper-label">Product</InputLabel>
                <Select
                    id="demo-simple-select-helper"
                    value={queryParams?.productId ? queryParams?.productId : []}
                    label="Product"
                    variant="outlined"
                    name="productId"
                    disabled={loading}
                    onChange={(event) =>handleFilterChange(event)}
                >
                    {ProductVariants.map((product)=>
                        <MenuItem key={`${product.value}${product.title}`} value={product.value}>{product.title}</MenuItem>
                    )}
                </Select>
            </FormControl>
            <FormControl sx={{ m: 1,  width: '10ch' }}  size="small">
                <div className={classes.editButton}
                     onClick={() => {
                         dispatch(Stories.clearFilters());
                         loadStories();
                     }}
                >Clear</div>
            </FormControl>
        </div>
    );
}