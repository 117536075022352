import {configureStore} from '@reduxjs/toolkit'
import {Stories, Users, Auth, Orders, Notifications, Authors, Analytics3d, OrdersTime} from './slices';
import * as middlewares from './middlewares';


const store = configureStore({
    reducer: {
        auth: Auth.reducer,
        stories: Stories.reducer,
        orders: Orders.reducer,
        users: Users.reducer,
        notifications: Notifications.reducer,
        authors: Authors.reducer,
        analytics3d: Analytics3d.reducer,
        ordersTime: OrdersTime.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(middlewares.auth)
            .concat(middlewares.stories)
            .concat(middlewares.orders)
            .concat(middlewares.users)
            .concat(middlewares.authors)
            .concat(middlewares.analytics3d)
            .concat(middlewares.ordersTime),
    preloadedState: {}
})

export default store;
