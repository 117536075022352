import { getFullAnalytics3d,} from '../../api';
import {createAsyncThunk} from "@reduxjs/toolkit";
import store from "../../store";
import {setNotifications} from "../slices/notifications";
import {logOutUser} from "./auth";

export const getAnalytics3d = createAsyncThunk(
    'analytics3d/getAnalytics3d',
    async (queryParams: any, {rejectWithValue}) => {
        try {
            const analytics3d = await getFullAnalytics3d(queryParams);
            return analytics3d;
        } catch (error: any) {
            if(error.message === 'Unauthorized!'){
                store.dispatch(logOutUser())
            }
            store.dispatch(setNotifications({text: error.message, type: 'error'}))
            return rejectWithValue(error.message)
        }
    }
);

