import React from 'react';
import Unknown from 'screens/Unknown';
import StoriesPage from 'screens/Stories';
import PrintfulPage from 'screens/Printful';
import StoryPage from 'screens/Stories/OneStory';
import OrderPage from 'screens/Printful/OneOrder';
import CreateStoryPage from 'screens/Stories/CreateStory';
import WithoutAccess from 'screens/WithoutAccess';
import Authors from 'screens/Authors';
import ImageQuality from "screens/ImageQuality";
import Analytics3D from "screens/3dAnalytics";
import TrackOrder from "screens/TrackOrder";
import ComingSoon from "screens/ComingSoon";

import { Navigate } from 'react-router-dom';

export const routes = (isLoggedIn, userRole) => {
  if (isLoggedIn) {
    switch (userRole) {
        case 'admin':
        case 'moderator':
          return [
            { path: '/', element: <Navigate to="/27768/orders" /> },
            { path: '/stories', element: <StoriesPage /> },
            { path: '/:id/orders', element: <PrintfulPage /> },
            { path: '/comingSoon', element: <ComingSoon /> },
            { path: '/companies', element: <ComingSoon /> },
            { path: '/products', element: <ComingSoon /> },
            { path: '/analytics', element: <ComingSoon /> },
            { path: '/stories/create', element: <CreateStoryPage /> },
            { path: '/authors', element: <Authors /> },
            { path: '/stories/:id/details', element: <StoryPage /> },
            { path: ':companyId/orders/:id/details', element: <OrderPage /> },
            { path: '/image-quality', element: <ImageQuality /> },
            { path: '/3d-analytics', element: <Analytics3D /> },
            { path: '/track-order', element: <TrackOrder /> },
            { path: '/*', element: <Unknown /> },
          ];
      case 'designer':
        return [
          { path: '/', element: <Navigate to="/27768/orders" /> },
          { path: ':companyId/orders/:id/details', element: <OrderPage /> },
          { path: '/:id/orders', element: <PrintfulPage /> },
          { path: '/*', element: <Unknown /> },
        ];
      case 'story_moderator':
        return [
          { path: '/', element: <Navigate to="/stories" /> },
          { path: '/stories/create', element: <CreateStoryPage /> },
          { path: '/stories', element: <StoriesPage /> },
          { path: '/authors', element: <Authors /> },
          { path: '/stories/:id/details', element: <StoryPage /> },
          { path: '/*', element: <Unknown /> },
        ];
      default: {
        return [
          { path: '/*', element: <Unknown /> },
          { path: '/', element: <WithoutAccess /> },
        ];
      }
    }
  }else {
    return [{ path: '/*', element: <Unknown /> }]
  }
};
