import {createSlice} from '@reduxjs/toolkit';
import {IOneOrder} from '../../types'
import reducers from './reducers'
import extraReducers from './extraReducers'

export * from '../../thunk/analytics3d';

const Analytics3dSlice = createSlice({
    name: "analytics3d",
    initialState: {
        data: {},
        status: null,
        error: null,
        queryParams: null,
    },
    reducers,
    extraReducers
});
const {actions, reducer} = Analytics3dSlice;
export const {setAnalytics3d, clearFilters} = actions;
export {reducer};
