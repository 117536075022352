import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: 'auto',
    minHeight: '100%',
    boxShadow: [0, 2, 13, 'rgba(0, 0, 0, 0.065696)'],
    borderRadius: 8,
    paddingLeft: 34,
    paddingRight: 34,
    paddingTop: 34,
    display: 'flex',
  },
  header: {
    marginTop: '10px',
    marginBottom: '30px',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    '&>div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  autocompleteContainer: {
    overflowY: 'scroll',
    boxShadow: [0, 4, 6, 'rgba(0, 0, 0, 0.12)'],
    borderRadius: 8,
    maxHeight: 150,
    '& div': {
      padding: 10,
    },
  },
  imgContainer: {
    maxWidth: 295,
    maxHeight: 295,
    marginTop: 195,
    borderRadius: 9,
    boxShadow: ['0px', '0px', '12px', '3px', 'rgba(34, 60, 80, 0.2)'],
    '& img': {
      maxWidth: 295,
      maxHeight: 295,
    },
  },
  dataContainer: {
    width: '100%',
    height: '100%',
    maxWidth: 'calc(100% - 310px)',
  },
  backArrow: {
    width: 30,
    height: '100%',
    paddingTop: 6,
    cursor: 'pointer',
  },

  subcategories: {
    display: 'flex',
    flexWrap: 'wrap',
    '& div:nth-child(n+2)': {
      marginLeft: 10,
    },
  },
  slugContainer: { display: 'flex' },
  detail: {
    width: '83%',
  },
  subcategoriesError: {
    fontFamily: 'Spartan',
    fontSize: 12,
    color: 'red',
  },
  saveChanges: {
    width: 142,
    height: 36,
    borderRadius: 8,
    lineHeight: '35px',
    backgroundColor: '#eeeeee',
    textAlign: 'center',
    marginLeft: -30,
  },
  saveActive: {
    cursor: 'pointer',
    color: 'white',
  },
  detailContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  detailTitle: {
    fontFamily: 'SpartanBold',
    fontWeight: 14,
    marginBottom: 11,
    marginTop: 30,
  },
  sizeTitle: {
    marginTop: 62,
  },
  grayBorder: {
    backgroundColor: 'red',
    fill: 'red',
  },
  textArea: {
    width: '100%',
  },
  orderStatus: {
    padding: '0 15px',
    borderRadius: '5px',
    backgroundColor: 'transparent',
    fontSize: 15,
    textAlign: 'center',
    fontFamily: 'Open Sans',
    maxHeight: '33px',
    lineHeight: '33px',
    marginLeft: '15px',
    '&.cancelled': {
      backgroundColor: 'rgb(102 102 102 / 50%)',
    },
    '&.pending': {
      backgroundColor: 'rgb(255 134 107 / 1)',
    },
    '&.packing': {
      backgroundColor: 'rgb(253 167 23 / 50%)',
    },
    '&.packed': {
      backgroundColor: 'rgb(251 235 150 / 50%)',
    },
    '&.delivering': {
      backgroundColor: 'rgba(180 188 72 / 50%)',
    },
    '&.delivered': {
      backgroundColor: 'rgba(12, 82, 104, 50%)',
      color: 'white',
    },
  },
  orderDate: {
    marginRight: '10%',
    fontFamily: 'Spartan',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    '& span': {
      fontWeight: '600',
    },
  },
  orderStatusBar: {
    paddingLeft: '35px',
  },
  userDetail: {
    width: '310px',
    height: '100%',
    paddingTop: '150px',
    paddingBottom: '20px',
    '&>div': {
      background: '#FFFFFF',
      boxShadow: '0px 2px 13px rgba(0, 0, 0, 0.065696)',
      borderRadius: '1px',
      height: 'auto',
      padding: [35, 25],
      fontFamily: 'Spartan',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '12px',
    },
  },
  titleItem: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Spartan',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    '& div': {
      padding: '5px 0',
    },
  },
  activeStatus: {
    fontWeight: '700',
    '& span': {
      borderBottom: '2px solid #FDA717',
      paddingBottom: '3px',
    }
  },
  productTableHeader: {
    background: '#FFFFFF',
    boxShadow: '0px 2px 13px rgba(0, 0, 0, 0.065696)',
    borderRadius: '1px',
    margin: [25, 10],
    padding: [0, 15],
    width: 'auto',
  },
  productTitle: {
    fontFamily: 'Spartan',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    padding: [20, 0],
  },
  productTableRow: {
    width: 'auto',
    margin: [25, 10],
    padding: [0, 15],
    borderBottom: '1px solid #EEEEEE',
  },
  productItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: '15px',
    fontFamily: 'Spartan',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
  },
  productImg: {
    width: '48px',
  },
  productBrand: {
    fontWeight: 'bold',
  },
  productName: {
    fontWeight: 'normal',
  },
  orderPrices: {
    margin: [25, 10],
    padding: [0, 15],
  },
  orderPriceItem: {
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'Spartan',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    margin: [10, 0],
    '& span': {
      minWidth: '100px'
    }
  },
  orderTotal: {
    marginTop: '20px',
    fontWeight: 'bold',
  },
  userHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    paddingBottom: '15px',
    borderBottom: '0.5px solid #EEEEEE',
    '&>div': {
      marginLeft: '17px',
    }
  },
  userIcon: {
    borderLeft: '4px solid #FDA717',
    paddingLeft: '17px',
  },
  userTitle: {
    fontWeight: '700',
    fontSize: '14px',
  },
  userDetailInfo: {
    marginTop: '27px',
    '&>div': {
      marginBottom: '10px',
    }
  },
  alignCenter: {
    textAlign: 'center',
  },
  scrollContainer: {
    maxHeight: 750,
    overflowY: 'scroll',
  },


  ///NEW ONE
  editButton: {
    padding:[8, 30],
    width: 'fit-content',
    backgroundColor: '#36e88b',
    color: 'black',
    cursor:'pointer',
    fontWeight: 600,
    borderRadius: 5,
    height: "40px",
    margin: '10px 10px',
    '&:hover': {
      backgroundColor: '#26af69',
    }
  },
  cancelButton: {
    padding:[8, 30],
    width: 'fit-content',
    backgroundColor: '#ff0000',
    color: 'white',
    cursor:'pointer',
    fontWeight: 600,
    borderRadius: 5,
    height: "40px",
    margin: '10px 10px',
    '&:hover': {
      backgroundColor: '#c00707',
    }
  },
  holdButton:{
    padding:[8, 30],
    width: 'fit-content',
    backgroundColor: '#40f8f8',
    color: 'black',
    cursor:'pointer',
    fontWeight: 600,
    borderRadius: 5,
    height: "40px",
    margin: '10px 10px',
    '&:hover': {
      backgroundColor: '#2eafaf',
    }
  }
});
