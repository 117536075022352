import * as React from 'react';
import { useNavigate } from 'react-router';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {Stories} from '../../store/slices'

import { useStyles } from './styles';
import {useDispatch} from "react-redux";
import {Line} from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);




const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#0000000a',
    },
}));




export default function OrdersTimeGraph(props: any) {
    const { data } = props;

    const classes = useStyles();
    const navigator = useNavigate();
    const dispatch = useDispatch();

    const labels = data.map(item => new Date(item.timestamp).toLocaleDateString()).reverse();
    const standardTimes = data.map(item => parseInt(item.standardProductionTime)).reverse();
    const expeditedTimes = data.map(item => parseInt(item.expeditedProductionTime)).reverse();

    // Налаштування графіка
    const chartData = {
        labels: labels,
        datasets: [
            {
                label: 'Standard Production Time',
                data: standardTimes,
                borderColor: 'blue',
                fill: false
            },
            {
                label: 'Expedited Production Time',
                data: expeditedTimes,
                borderColor: 'red',
                fill: false
            }
        ]
    };
    const options = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Timestamp'
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Production Time'
                }
            }
        }
    };

    // const handleChangePage = (event: unknown, newPage: number) => {
    //     pagingUpdate({page: newPage+1, perPage: paging.perPage})
    // };
    //
    // const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     pagingUpdate({page: 1, perPage: +event.target.value})
    // };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Line data={chartData} options={options}/>
        </Paper>
    );
}